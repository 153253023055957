import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { screenSizeMatcher } from '../utilities/style-utils';
import { screenSizeUpdated } from '../actions/screen-size-actions';
import { Dispatch } from '../types';

const FullHeightContainer = styled.div`
  height: 100%;
`;

type Props = { dispatch: Dispatch; children?: React.ReactNode };

const DeviceTypeListener = ({ dispatch, children }: Props) => {
  useEffect(() => {
    if (!screenSizeMatcher) return;

    const onScreenSizeUpdated = ({ matches }: MediaQueryListEvent) => {
      dispatch(screenSizeUpdated(matches ? 'mobile' : 'desktop'));
    };

    try {
      /**
       * Improved API but supported by only ~94.2% of browsers
       * https://caniuse.com/mdn-api_mediaquerylist_change_event
       */
      screenSizeMatcher.addEventListener('change', onScreenSizeUpdated);
      return () => screenSizeMatcher.removeEventListener('change', onScreenSizeUpdated);
    } catch (e) {
      /**
       * These methods are deprecated but needed as fallbacks for browsers older than:
       * - Edge 79 (released 2020-01)
       * - Safari 14 (released 2020-09)
       * - Safari on iOS 14 (released 2020-09)
       *
       * All other browsers have had support since 2014-2017.
       * https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/change_event
       */
      screenSizeMatcher.addListener(onScreenSizeUpdated);
      return () => screenSizeMatcher.removeListener(onScreenSizeUpdated);
    }
  }, [dispatch]);

  return <FullHeightContainer>{children}</FullHeightContainer>;
};

export default connect()(DeviceTypeListener);
