export var defaultGridSize = 8;
export var defaultGridColumns = 12;
export var defaultGridColumnWidth = defaultGridSize * 10;
export var spacingMapping = {
  comfortable: defaultGridSize * 5,
  cosy: defaultGridSize * 2,
  compact: defaultGridSize * 0.5
};
export var defaultSpacing = 'cosy';
export var defaultBannerHeight = 52;

// Corresponds to an `auto` width/flex-basis
export var defaultMedium = 0;
export var defaultLayout = 'fixed';

/**
 * The number of available columns in each row.
 */
export var varColumnsNum = '--ds-columns-num';

/**
 * The number of columns that a `GridColumn` covers.
 */
export var varColumnSpan = '--ds-column-span';

/**
 * The spacing (in `px`) between each column.
 */
export var varGridSpacing = '--ds-grid-spacing';