import trelloImageLeft from '../../../images/cobranding/trello/trello-left.svg';
import trelloImageRight from '../../../images/cobranding/trello/trello-right.svg';
import { TrelloLogo } from '@atlaskit/logo';
import { backgroundWithSideImages, CobrandingComponents } from '../shared-cobranding';

export const TrelloBackground = backgroundWithSideImages(trelloImageLeft, trelloImageRight);

const TrelloCobranding: CobrandingComponents = {
  background: TrelloBackground,
  logo: { component: TrelloLogo },
  footerProducts: 'Trello, Jira, Confluence',
};

export default TrelloCobranding;
