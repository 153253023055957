export interface State {
  displayName: string;
  email: string;
  avatarUrl: string;
  continueUrl: string;
}

export const defaultState: State = {
  displayName: '',
  email: '',
  avatarUrl: '',
  continueUrl: '',
};

const continueAs = (state: State = defaultState): State => {
  return state;
};

export default continueAs;
