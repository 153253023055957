import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback, useState } from 'react';
export var useForceRender = function useForceRender() {
  var _useState = useState({}),
    _useState2 = _slicedToArray(_useState, 2),
    updateState = _useState2[1];
  var forceRender = useCallback(function () {
    return updateState({});
  }, []);
  return forceRender;
};