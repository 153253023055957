import {
  Action,
  MultifactorMethod,
  MultiFactorOTPError,
  MultiFactorRecoveryError,
  RecoveryEmailErrorReason,
} from '../types';

export interface State {
  email: string;
  fieldErrors: { otpCode?: MultiFactorOTPError; recoveryCode?: MultiFactorRecoveryError };
  isLoading: boolean;
  isManaged: boolean;
  isRecoveryEmailSent: boolean;
  recoveryEmailError?: RecoveryEmailErrorReason;
  method?: MultifactorMethod;
  userId?: string;
  phoneNumber?: string;
  recoveryCode?: string;
}

export const defaultState: State = {
  email: '',
  fieldErrors: {},
  isLoading: false,
  isManaged: true,
  isRecoveryEmailSent: false,
};

export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case 'MFA_VERIFICATION_VERIFY':
      return {
        ...state,
        fieldErrors: {},
        isLoading: true,
      };
    case 'MFA_VERIFICATION_RECOVER':
      return {
        ...state,
        fieldErrors: {},
        isLoading: true,
      };
    case 'MFA_VERIFICATION_RECOVER_RESPONSE':
      return {
        ...state,
        recoveryCode: action.recoveryCode,
      };
    case 'MFA_VERIFICATION_VERIFY_ERROR':
      return {
        ...state,
        isLoading: false,
        fieldErrors: {
          otpCode: { errorCode: action.errorCode, message: action.message },
        },
      };
    case 'MFA_VERIFICATION_RECOVER_ERROR':
      return {
        ...state,
        isLoading: false,
        fieldErrors: {
          recoveryCode: { errorCode: action.errorCode, message: action.message },
        },
      };
    case 'RECOVERY_EMAIL_SENT':
      return {
        ...state,
        isRecoveryEmailSent: true,
      };
    case 'RECOVERY_EMAIL_START':
      return {
        ...state,
        recoveryEmailError: undefined,
      };
    case 'RECOVERY_EMAIL_ERROR':
      return {
        ...state,
        recoveryEmailError: action.reason,
      };
    case 'USER_MANAGED_STATUS':
      return {
        ...state,
        isManaged: action.isManaged,
      };

    default:
      return state;
  }
};
