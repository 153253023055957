import { Action } from '../types';

export interface State {
  dismissed: boolean;
}

export const defaultState: State = {
  dismissed: false,
};

const googleOneTap = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case 'GOOGLE_ONE_TAP_DISMISS':
      return {
        ...state,
        dismissed: true,
      };
    default:
      return state;
  }
};

export default googleOneTap;
