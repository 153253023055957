import { Action, VerificationType, VerifyOrReverifyEmailError } from '../types';

export interface State {
  verificationType: VerificationType;
  url: string;
  error: VerifyOrReverifyEmailError;
}

export const defaultState = {
  verificationType: VerificationType.Verify,
  url: '#',
  error: VerifyOrReverifyEmailError.ExpiredToken,
};

const verifyEmail = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    default:
      return state;
  }
};

export default verifyEmail;
