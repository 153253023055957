import Button, { ButtonProps } from '@atlaskit/button/standard-button';
import styled from '@emotion/styled';
import * as React from 'react';

const StyledButton = styled(Button)`
  span {
    font-weight: 400;
  }
`;

const ButtonLink = (props: ButtonProps) => {
  const { appearance, ...rest } = props;
  return <StyledButton appearance={appearance || 'link'} {...rest} />;
};

export default ButtonLink;
