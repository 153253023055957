import { Action, ResetExpiredPasswordError } from '../types';

export interface State {
  error: ResetExpiredPasswordError | null;
  isLoading: boolean;
  isComplete: boolean;
}

export const defaultState: State = {
  error: null,
  isLoading: false,
  isComplete: false,
};

const expiredPassword = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case 'EXPIRED_PASSWORD_RESET_REQUEST_INITIATE':
      return {
        ...state,
        error: null,
        isLoading: true,
        isComplete: false,
      };
    case 'EXPIRED_PASSWORD_RESET_REQUEST_SUCCESS':
      return {
        ...state,
        error: null,
        isLoading: false,
        isComplete: true,
      };
    case 'EXPIRED_PASSWORD_RESET_REQUEST_FAILED':
      return {
        ...state,
        error: action.error,
        isLoading: false,
        isComplete: true,
      };
    default:
      return state;
  }
};

export default expiredPassword;
