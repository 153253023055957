import { Action, ServerError } from '../types';

export type State = ServerError | null;

export const defaultState = null;

const serverErrorReducer = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case 'SERVER_ERROR_UPDATE':
      return action.error;
    default:
      return state;
  }
};

export default serverErrorReducer;
