import { Action, VerificationType } from '../types';

export interface State {
  verificationType: VerificationType;
  showBrowserWarning: boolean;
}

export const defaultState = {
  verificationType: VerificationType.Verify,
  showBrowserWarning: false,
};

const verifyEmailSent = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    default:
      return state;
  }
};

export default verifyEmailSent;
