import { Action, CsrfToken, HashedCsrfToken } from '../types';

export type State = CsrfToken | HashedCsrfToken;

export const defaultState: State = '';

export const csrfToken = (state: State = defaultState, action: Action): CsrfToken => {
  if (action.type === 'CSRF_TOKEN_UPDATE') {
    return action.csrfToken;
  }
  return state;
};
export const hashedCsrfToken = (state: State = defaultState, action: Action): HashedCsrfToken => {
  if (action.type === 'HASHED_CSRF_TOKEN_UPDATE') {
    return action.hashedCsrfToken;
  }
  return state;
};
