import { Buffer } from 'buffer';
export var getEnvGlobals = function getEnvGlobals() {
  return {
    atob:
    // atob/btoa need to be bound because they throw an illegal invocation error when called as a method (obj.atob())
    typeof atob === 'function' && atob.bind(undefined) || typeof Buffer !== 'undefined' && function (encoded) {
      return Buffer.from(encoded, 'base64').toString();
    },
    btoa: typeof btoa === 'function' && btoa.bind(undefined) || typeof Buffer !== 'undefined' && function (str) {
      return Buffer.from(str).toString('base64');
    },
    URLSearchParams: typeof URLSearchParams === 'function' && URLSearchParams || typeof require === 'function' && require('url').URLSearchParams
  };
};
var env;
export var getEnv = function getEnv() {
  return env || (env = validateEnv(getEnvGlobals()));
};
var validateEnv = function validateEnv(env) {
  if (!(env.atob && env.btoa && env.URLSearchParams)) {
    throw new TypeError("Origin-Tracing: missing environment APIs. Required: btoa(), atob(), URLSearchParams() (or in Node: Buffer, require('url').URLSearchParams).");
  }
  return env;
};