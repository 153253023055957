import { Action } from '../types';

export interface State {
  redirectTo: string | null;
}

export const defaultState: State = {
  redirectTo: null,
};

export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case 'MFA_PROMOTE_REDIRECT':
      return {
        ...state,
        redirectTo: action.redirectTo,
      };
    default:
      return state;
  }
};
