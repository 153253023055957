import React, { useLayoutEffect, useRef } from 'react';

import { browserMetrics } from '@atlassian/browser-metrics';
import { useLocation } from 'react-router-dom';

type BrowserMetricsBoundaryProps = {
  children: React.ReactNode;
};

export const BrowserMetricsBoundary = ({ children }: BrowserMetricsBoundaryProps) => {
  const prevPathname = useRef<string>();
  const { pathname } = useLocation();

  /*
   * Here we only want to capture transition page loads (i.e. clientside route changes).
   *
   * Initial page loads (with a full trip to the server) are already recorded separately,
   * by calling startPageLoad before the react app is bootstrapped.
   *
   * And if startPageLoad were called again here, that initial page load will be overridden
   * and incorrectly recorded as a transition.
   */
  useLayoutEffect(() => {
    if (!pathname) {
      return;
    }

    if (!prevPathname.current) {
      prevPathname.current = pathname;
      return;
    }

    // `isInitial: false` is the default, but made explicit here for clarity
    browserMetrics.startPageLoad({ isInitial: false });
  }, [pathname, prevPathname]);

  return <>{children}</>;
};
