import { Action, OidcContext } from '../types';
import { ScreenSize } from '../utilities/style-utils';

export interface Microbranding {
  isEmbedded: boolean;
  isAnyMobile: boolean;
  isMobileApp: boolean;
  screenSize: ScreenSize;
  oidcContext?: OidcContext;
}

export type State = Microbranding;

export const defaultState: State = {
  oidcContext: undefined,
  isEmbedded: false,
  isAnyMobile: false,
  isMobileApp: false,
  screenSize: 'unknown',
};

const microbranding = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case 'MICROBRANDING_UPDATE':
      return {
        ...state,
        ...action.microbranding,
      };
    case 'SCREEN_SIZE_UPDATED':
      return {
        ...state,
        screenSize: action.screenSize,
      };
    default:
      return state;
  }
};

export default microbranding;
