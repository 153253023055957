import { JiraAlignLogo } from '@atlaskit/logo';
import { DefaultBackground, CobrandingComponents } from '../shared-cobranding';
import styled from '@emotion/styled';

export const JiraAlignBackground = styled(DefaultBackground)`
  background-color: #fff;
`;

const JiraAlignCobranding: CobrandingComponents = {
  background: JiraAlignBackground,
  logo: { component: JiraAlignLogo },
  footerProducts: 'Jira Align, Confluence, Jira',
};

export default JiraAlignCobranding;
