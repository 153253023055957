import { Action, Flag } from '../types';

export interface State {
  flags: Flag[];
}

export const defaultState: State = {
  flags: [],
};

export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case 'FLAG_ADD':
      return {
        ...state,
        flags: [action.flag, ...state.flags],
      };
    case 'FLAG_DISMISS':
      return {
        ...state,
        flags: state.flags.slice(1),
      };
    default:
      return state;
  }
};
