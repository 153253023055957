import { Action, WelcomeSentError, RedirectType } from '../types';
import { Application } from '../utilities/applications';

export interface State {
  error: WelcomeSentError | null;
  email: string;
  redirectType: RedirectType;
  showBrowserWarning: boolean;
  migratingApplications?: Application[];
}

export const defaultState = {
  error: null,
  email: '',
  redirectType: RedirectType.ResumeMigration,
  showBrowserWarning: false,
};

const welcomeSent = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    default:
      return state;
  }
};

export default welcomeSent;
