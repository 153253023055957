/* eslint-disable @atlaskit/design-system/prefer-primitives */
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var Background = styled.div({
  position: 'fixed',
  background: '#fff',
  height: '100%',
  width: '100%'
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var DarkBackground = styled.div({
  position: 'fixed',
  background: '#2c333a',
  width: '100%',
  height: '100%'
});