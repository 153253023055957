import React from 'react';
import * as queryString from 'query-string';
import { FormattedMessage, useIntl } from 'react-intl';
import errorImage from '../images/Error.svg';
import { URLParameters } from '../types';
import messages from './CsrfTokenMismatchErrorPage.messages';
import WhiteboxPage from '../components/WhiteboxPage';
import TallButton from '../components/TallButton';
import ButtonContainer from '../components/ButtonContainer';
import {
  useAnalyticsClient,
  usePageViewedEvent,
} from '../utilities/analytics/analytics-web-client';
import styled from '@emotion/styled';
import { gridSize } from '@atlaskit/theme';

const pageId = 'atlassianAccountCsrfTokenMismatchErrorScreen';

const StyledOptions = styled.ul`
  padding-left: ${3 * gridSize()}px;
`;

export const CsrfTokenMismatchErrorPage = () => {
  const { formatMessage } = useIntl();
  const analyticsClient = useAnalyticsClient();

  const preservedParamsKeys = [
    'application',
    'continue',
    'display',
    'email',
    'login_hint',
    'tenant',
  ];
  const originalParams: URLParameters = queryString.parse(window.location.search);
  const preservedParams: URLParameters = Object.keys(originalParams)
    .filter(key => preservedParamsKeys.includes(key))
    .reduce((obj, key) => {
      obj[key] = originalParams[key];
      return obj;
    }, {});

  usePageViewedEvent(pageId, { application: preservedParams.application });

  const tryLoginAgainAction = () => {
    analyticsClient.buttonClickedEvent(pageId, 'csrfTokenMismatchTryAgainButton', {
      application: preservedParams.application,
    });
    const queryParamsString = queryString.stringify(preservedParams);
    window.location.assign('/login' + (queryParamsString ? `?${queryParamsString}` : ''));
  };

  return (
    <WhiteboxPage
      pageId={pageId}
      header={<FormattedMessage tagName="h5" {...messages.csrfMismatchTitle} />}>
      <img src={errorImage} height="150" alt="" className="error-image" />
      <br />
      <div className="error-description">
        <FormattedMessage {...messages.csrfMismatchDescriptionFirstLine} />
        <StyledOptions>
          <li>
            <FormattedMessage
              id="error.csrfmismatch.description.secondline"
              defaultMessage="Close any <b>{siteName}</b> windows in your browser"
              values={{
                b: chunks => <strong>{chunks}</strong>,
                siteName: window.location.hostname,
              }}
            />
          </li>
          <li>
            <FormattedMessage {...messages.csrfMismatchDescriptionThirdLine} />
          </li>
        </StyledOptions>
      </div>
      <ButtonContainer>
        <TallButton
          appearance="primary"
          id="try-again-button"
          isFullWidth
          onClick={tryLoginAgainAction}>
          {formatMessage(messages.csrfMismatchButtonText)}
        </TallButton>
      </ButtonContainer>
    </WhiteboxPage>
  );
};

export default CsrfTokenMismatchErrorPage;
