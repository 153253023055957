import React from 'react';
import defaultImageLeft from '../../../images/cobranding/default/default_left.svg';
import defaultImageRight from '../../../images/cobranding/default/default_right.svg';
import AtlasLogo from './AtlasLogo';
import { backgroundWithSideImages, CobrandingComponents } from '../shared-cobranding';

export const AtlasBackground = backgroundWithSideImages(defaultImageLeft, defaultImageRight);

const AtlasCobranding: CobrandingComponents = {
  background: AtlasBackground,
  logo: {
    renderLogo: () => <AtlasLogo size={'large'} />,
  },
  footerProducts: 'Trello, Jira, Confluence',
};

export default AtlasCobranding;
