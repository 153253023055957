import { Action } from '../types';

export interface State {
  config?: MarketingConsentConfig;
}

export interface MarketingConsentConfig {
  showCheckbox: boolean;
  implicitConsent: boolean;
  locale: string;
}

export const defaultState: State = {
  config: undefined,
};
const marketingConsent = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case 'UPDATE_MARKETING_CONSENT_CONFIG':
      return {
        ...state,
        config: action.config,
      };
    default:
      return state;
  }
};

export default marketingConsent;
