import { BitbucketLogo } from '@atlaskit/logo';
import { BitbucketBackground } from '@atlassian/product-wireframes';
import { CobrandingComponents } from '../shared-cobranding';

const BitbucketCobranding: CobrandingComponents = {
  background: BitbucketBackground,
  logo: { component: BitbucketLogo },
  footerProducts: 'Bitbucket, Jira, Confluence',
};

export default BitbucketCobranding;
