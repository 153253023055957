import FeatureGates from '@atlaskit/feature-gate-js-client';
import { AidFrontendFeatureGate } from '../../providers/FeatureGates';

export const checkFeatureGate = (key: AidFrontendFeatureGate) => {
  if (process.env.NODE_ENV === 'development' && !FeatureGates.initializeCompleted()) {
    console.warn(`Feature flag ${key} accessed before initialize completed`);
  }

  return FeatureGates.checkGate(key);
};
