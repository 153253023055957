import { Action, EmailConfirmationError } from '../types';

export interface State {
  email: string;
  newEmail: string;
  error: EmailConfirmationError | null;
}

export const defaultState = {
  email: '',
  newEmail: '',
  error: null,
};

const changeEmail = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    default:
      return state;
  }
};

export default changeEmail;
