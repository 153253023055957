import React, { Component, ErrorInfo } from 'react';
import * as Sentry from '@sentry/browser';

import { AnalyticsClient, EventAttributes } from '../utilities/analytics/analytics-web-client';
import { BaseErrorPage as ErrorBoundaryErrorPage } from './ErrorPage';
import { FormattedMessage } from 'react-intl';
interface Props {
  children: React.ReactNode;
  analyticsClient: AnalyticsClient;
}

interface State {
  errorType?: 'network' | 'unexpected';
}

export default class ErrorBoundary extends Component<Props, State> {
  static pageId = 'unexpectedErrorPage';

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Failed to fetch dynamically imported module:
    // Importing a module script failed.
    // error loading dynamically imported module:
    // Cannot find module
    if (/(import|find).*.module/i.test(error.message)) {
      this.setState({ errorType: 'network' });
      return;
    }

    this.setState({ errorType: 'unexpected' });

    const attributes: EventAttributes = {
      errorName: error.name,
      errorMessage: error.message,
      errorComponentStack: errorInfo.componentStack || undefined,
      errorStack: error.stack,
    };

    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      scope.setExtra('ErrorBoundary.action', 'loadPageFailure');
      scope.setExtra('ErrorBoundary.pageId', ErrorBoundary.pageId);

      Object.keys(attributes).forEach(key => {
        scope.setExtra(`ErrorBoundary.${key}`, attributes[key]);
      });

      Sentry.captureException(error);
    });

    this.props.analyticsClient.operationalEvent({
      page: ErrorBoundary.pageId,
      action: 'loadPageFailure',
      subject: 'sli',
      attributes,
    });
  }

  render() {
    const { errorType } = this.state;
    const anonymousId = this.props.analyticsClient.getAnonymousId();

    if (errorType === 'network') {
      return (
        <ErrorBoundaryErrorPage
          title={
            <FormattedMessage
              id="error.boundary.error.title"
              defaultMessage="Something went wrong"
            />
          }
          anonymousId={anonymousId}>
          <FormattedMessage
            id="error.boundary.network.error"
            defaultMessage="We were unable to load this page due to a network connectivity issue. Please check your network connection and try again."
            tagName="p"
          />
        </ErrorBoundaryErrorPage>
      );
    }

    if (errorType === 'unexpected') {
      return <UnexpectedApplicationErrorPage anonymousId={anonymousId} />;
    }

    return this.props.children;
  }
}

export const UnexpectedApplicationErrorPage = ({ anonymousId }: { anonymousId?: string }) => (
  <ErrorBoundaryErrorPage
    title={
      <FormattedMessage id="error.boundary.error.title" defaultMessage="Something went wrong" />
    }
    anonymousId={anonymousId}>
    <FormattedMessage
      id="error.boundary.unexpected.error"
      defaultMessage="An unexpected error occurred. We are looking into it. Please try again later."
      tagName="p"
    />
  </ErrorBoundaryErrorPage>
);
