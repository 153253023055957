export interface State {
  redirectTo: string;
}

export const defaultState: State = {
  redirectTo: '',
};

const migrateConfirmation = (state: State = defaultState): State => {
  return state;
};

export default migrateConfirmation;
