const perf = typeof window !== 'undefined' ? window.performance : null;

export function getFirstPaint() {
  try {
    if (!perf) {
      return null;
    }
    const fp = perf.getEntriesByType('paint').find(({ name }) => name === 'first-paint');
    return fp ? Math.round(fp.startTime) : null;
  } catch (err) {
    return null;
  }
}

export function getFirstContentfulPaint() {
  try {
    if (!perf) {
      return null;
    }
    const fcp = perf
      .getEntriesByType('paint')
      .find(({ name }) => name === 'first-contentful-paint');
    return fcp ? Math.round(fcp.startTime) : null;
  } catch (err) {
    return null;
  }
}
