import { Action } from '../types';

export type State = string;

export const defaultState: State = 'en';

const locale = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    default:
      return state;
  }
};

export default locale;
