import styled from '@emotion/styled';

export const BlankBackground = styled.main({
  alignItems: 'stretch',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  justifyContent: 'space-between',
  margin: '0 auto',
  minHeight: '100vh',
  maxWidth: '432px',
  padding: '2rem 1rem 3rem',
  textAlign: 'center',
});

export const IdentityWrapper = styled.section({
  flex: 1,
  justifySelf: 'flex-start',
  textAlign: 'left',
  '#WhiteboxContainer, #ProductHeading': {
    margin: 0,
  },
  '#ProductHeadingSuffix': {
    alignItems: 'stretch',
    marginBlockEnd: '1rem',
    paddingBlockStart: '1rem',
    width: '100%',
    'h2, h5': {
      fontWeight: 500,
      textAlign: 'left',
      width: '100%',
    },
    h2: {
      fontSize: '2rem',
      margin: '10px 0 0',
    },
    h5: {
      fontSize: '1rem',
      margin: 0,
    },
  },
  section: {
    padding: 0,
    width: '100%',
  },
});
