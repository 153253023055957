import { Action, MessageDescriptor } from '../types';
import UUID from 'uuid';

export const addFlag = (
  type: 'success' | 'error',
  title: MessageDescriptor,
  description: MessageDescriptor
): Action => ({
  type: 'FLAG_ADD',
  flag: {
    type,
    title,
    description,
    id: UUID.v4(),
  },
});

export const dismissFlag = (): Action => ({
  type: 'FLAG_DISMISS',
});
