/* eslint-disable @atlaskit/design-system/prefer-primitives */
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { HEADER_HEIGHT } from './constants';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var Wrapper = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  '@media (min-width: 1200px)': {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    'rect.small, path.small': {
      visibility: 'hidden'
    },
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    'rect.medium, path.medium': {
      visibility: 'hidden'
    },
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    'rect.large, path.large': {
      visibility: 'visible'
    }
  },
  '@media (min-width: 1000px) and (max-width: 1200px)': {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    'rect.small, path.small': {
      visibility: 'hidden'
    },
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    'rect.large, path.large': {
      visibility: 'hidden'
    },
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    'rect.medium, path.medium': {
      visibility: 'visible'
    }
  },
  '@media (max-width: 1000px)': {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    'rect.medium, path.medium': {
      visibility: 'hidden'
    },
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    'rect.large, path.large': {
      visibility: 'hidden'
    },
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
    'rect.small, path.small': {
      visibility: 'visible'
    }
  }
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var Line = styled.div({
  display: 'inline-block',
  position: 'fixed',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  top: "".concat(HEADER_HEIGHT, "px"),
  left: 0,
  height: '2px',
  width: '100%',
  background: '#dfe1e6',
  zIndex: 1
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var DarkModeLine = styled.div({
  display: 'inline-block',
  position: 'fixed',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  top: "".concat(HEADER_HEIGHT, "px"),
  left: 0,
  height: '2px',
  width: '100%',
  background: '#454f59',
  zIndex: 1
});