import { LoomLogo } from '@atlaskit/logo';
import { DefaultBackground, CobrandingComponents } from '../shared-cobranding';
import styled from '@emotion/styled';

export const LoomBackground = styled(DefaultBackground)`
  background-color: #fff;
`;

const LoomCobranding: CobrandingComponents = {
  background: LoomBackground,
  logo: { component: LoomLogo },
  footerProducts: 'Loom, Confluence, Jira',
};

export default LoomCobranding;
