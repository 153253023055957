import { Action } from '../types';

export interface State {
  email: string;
  mfaToken: string;
  orgName: string | null;
  token: string | null;
}

export const defaultState = {
  email: '',
  mfaToken: '',
  orgName: null,
  token: null,
};

const stepUp = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case 'STEP_UP_RESEND_CODE_SUCCESS':
      return {
        ...state,
        mfaToken: action.mfaToken,
      };
    case 'STEP_UP_SEND_CODE_SUCCESS':
      return {
        ...state,
        mfaToken: action.mfaToken,
      };
    case 'STEP_UP_MFA_TOKEN_LOAD_FROM_SESSION_STORAGE':
      return {
        ...state,
        mfaToken: action.mfaToken,
      };
    default:
      return state;
  }
};

export default stepUp;
