import { Action } from '../types';

export interface State {
  tenantCloudId?: string;
  userId?: string;
}

export const defaultState: State = {
  tenantCloudId: undefined,
};

const appConfig = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    default:
      return state;
  }
};

export default appConfig;
