import { Action } from '../types';

export interface State {
  userData?: UserData;
  formError?: string;
  autoSubmit: boolean;
}

export interface UserData {
  email: string;
  avatarUrl: string;
  displayName?: string;
  associatedSessions: AssociatedSession[];
}

export interface AssociatedSession {
  email?: string;
}

export const defaultState: State = {
  autoSubmit: false,
};

const logout = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    default:
      return state;
  }
};

export default logout;
