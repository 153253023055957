import { ConfluenceLogo } from '@atlaskit/logo';
import { ConfluenceBackground } from '@atlassian/product-wireframes';
import { CobrandingComponents } from '../shared-cobranding';

const ConfluenceCobranding: CobrandingComponents = {
  background: ConfluenceBackground,
  logo: { component: ConfluenceLogo },
  footerProducts: 'Confluence, Jira, Trello',
};

export default ConfluenceCobranding;
