import { Action } from '../types';

export interface State {
  email: string;
  fullName?: string;
  timezone?: string;
  emailHash?: string;
  title?: string;
  org?: string;
}

export const defaultState = {
  email: '',
};

const user = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    default:
      return state;
  }
};

export default user;
