import React from 'react';
import styled from '@emotion/styled';
import Flag, { FlagGroup } from '@atlaskit/flag';
import { colors, gridSize } from '@atlaskit/theme';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { useIntl } from 'react-intl';
import { Flag as FlagType } from '../types';
import { dismissFlag } from '../actions/flag-actions';
import { useAppDispatch, useAppSelector } from '../stores/redux.store';

// We need to wrap the flag in a div to apply responsive styles so
// the flag are always visible on smaller viewports.
const ResponsiveFlagWrapper = styled.div`
  [role='alert'] {
    max-width: calc(100vw - ${gridSize() * 11}px);
  }
`;

export const FlagComponent = () => {
  const { formatMessage } = useIntl();
  const flags = useAppSelector(state => state.flags.flags);
  const dispatch = useAppDispatch();

  const toFlagComponent = (flag: FlagType) => {
    const icon =
      flag.type === 'success' ? (
        <SuccessIcon label="Success" primaryColor={colors.G300} />
      ) : (
        <ErrorIcon label="Error" primaryColor={colors.R300} />
      );
    return (
      <ResponsiveFlagWrapper id={flag.id} key={flag.id}>
        <Flag
          id={flag.id || ''}
          title={formatMessage(flag.title)}
          description={formatMessage(flag.description)}
          icon={icon}
          onDismissed={() => dispatch(dismissFlag())}
        />
      </ResponsiveFlagWrapper>
    );
  };

  return <FlagGroup>{flags.map(toFlagComponent)}</FlagGroup>;
};

export default FlagComponent;
