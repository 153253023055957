import opsgenieImageLeft from '../../../images/cobranding/opsgenie/opsgenie-left.svg';
import opsgenieImageRight from '../../../images/cobranding/opsgenie/opsgenie-right.svg';
import { OpsgenieLogo } from '@atlaskit/logo';
import { backgroundWithSideImages, CobrandingComponents } from '../shared-cobranding';

export const OpsgenieBackground = backgroundWithSideImages(opsgenieImageLeft, opsgenieImageRight);

const OpsgenieCobranding: CobrandingComponents = {
  background: OpsgenieBackground,
  logo: { component: OpsgenieLogo },
  footerProducts: 'Opsgenie, Jira, Confluence',
};

export default OpsgenieCobranding;
