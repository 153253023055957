import { Action } from '../types';

export interface State {
  ssoRedirectUrl: string | undefined;
  idp: { trusted: boolean; humanReadableRedirectLocation: string | undefined };
}

export const defaultState: State = {
  ssoRedirectUrl: undefined,
  idp: { trusted: false, humanReadableRedirectLocation: undefined },
};
const stepUpSso = (state: State = defaultState, action: Action): State => {
  return state;
};

export default stepUpSso;
