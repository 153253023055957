import { AtlassianLogo } from '@atlaskit/logo';
import { backgroundWithSideImages, CobrandingComponents } from './shared-cobranding';
import defaultImageLeft from '../../images/cobranding/default/default_left.svg';
import defaultImageRight from '../../images/cobranding/default/default_right.svg';

export const DefaultCobrandingBackground = backgroundWithSideImages(
  defaultImageLeft,
  defaultImageRight
);

const DefaultCobranding: CobrandingComponents = {
  background: DefaultCobrandingBackground,
  logo: { component: AtlassianLogo },
  footerProducts: 'Jira, Confluence, Trello',
};

export default DefaultCobranding;
