import statuspageImageLeft from '../../../images/cobranding/statuspage/statuspage-left.svg';
import statuspageImageRight from '../../../images/cobranding/statuspage/statuspage-right.svg';
import { StatuspageLogo } from '@atlaskit/logo';
import { backgroundWithSideImages, CobrandingComponents } from '../shared-cobranding';

export const StatuspageBackground = backgroundWithSideImages(
  statuspageImageLeft,
  statuspageImageRight
);

const StatuspageCobranding: CobrandingComponents = {
  background: StatuspageBackground,
  logo: { component: StatuspageLogo },
  footerProducts: 'Statuspage, Jira, Confluence',
};

export default StatuspageCobranding;
