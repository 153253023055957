import { Action } from '../types';

export type State = boolean;

const globalStateLoading = (state: State = false, action: Action) => {
  if (action.type === 'SET_GLOBAL_STATE_LOADING') {
    return action.isLoading;
  }
  return state;
};

export default globalStateLoading;
