import React from 'react';
import {
  FeatureGatesInitialization,
  FeatureGatesInitializationProps,
} from '@atlassian/feature-gates-react';
import { ClientOptions, FeatureGateEnvironment } from '@atlaskit/feature-gate-js-client';
import { currentEnv } from '../utilities/env';
import { getStatsigApiKey, statsigTargetApp } from './common';

export type FeatureGatesInitProps = Pick<
  FeatureGatesInitializationProps,
  'identifiers' | 'initializingComponent'
> & {
  onReady: (err?: Error | undefined) => void;
};

const getLocalMode = () => currentEnv === 'local';

const getEnv = (): FeatureGateEnvironment => {
  switch (currentEnv) {
    case 'dev':
    case 'local':
      return FeatureGateEnvironment.Development;
    case 'stg':
    case 'stg-fedm':
      return FeatureGateEnvironment.Staging;
    case 'prod':
    case 'prod-fedm':
    default:
      return FeatureGateEnvironment.Production;
  }
};

const options: ClientOptions = {
  targetApp: statsigTargetApp,
  environment: getEnv(),
  apiKey: getStatsigApiKey(),
  localMode: getLocalMode(),
};

export const FeatureGatesInit = ({ identifiers, onReady }: FeatureGatesInitProps) => {
  return (
    <FeatureGatesInitialization
      enabled
      identifiers={identifiers}
      options={options}
      onReady={onReady}
    />
  );
};
