import { AtlassianLogo } from '@atlaskit/logo';
import wacImage from '../../../images/cobranding/wac/wac.svg';
import { CobrandingComponents, backgroundWithImage } from '../shared-cobranding';

export const WacBackground = backgroundWithImage(wacImage);

const WacCobranding: CobrandingComponents = {
  background: WacBackground,
  logo: { component: AtlassianLogo },
  footerProducts: 'Jira, Confluence, Trello',
};

export default WacCobranding;
