import { pageLoadMetaMetric } from '../../metric/page-load-meta-metric';
import { isPageSegmentLoadConfig } from '../../metric/types';
import { BMPageLoadMetrics, BMPageSegmentLoadMetrics, isDetailedPageSegmentLoadSlo, PageSegmentPageLoadMetricsOptions } from '../../types';
var getThreshold = function getThreshold(isInitial, metric) {
  return isDetailedPageSegmentLoadSlo(metric) ? isInitial ? metric.initial.threshold : metric.transition.threshold : metric.threshold;
};
var getHistogram = function getHistogram(isInitial, metric, histogram) {
  return isInitial ? histogram.initial[metric] : histogram.transition[metric];
};
export var pageSegmentLoadMetrics = function pageSegmentLoadMetrics(config, data, globalConfig) {
  var _config$slo, _config$slo2;
  if (!isPageSegmentLoadConfig(config)) {
    throw new Error('Browser Metrics: cannot gather page segment load data for non-page-segment-load config');
  }
  var fmp = data.marks[BMPageSegmentLoadMetrics.fmp] || data.stop;
  var result = {
    'metric:fmp': Math.round(fmp - data.start),
    'metric:tti': Math.round(data.stop - data.start)
  };
  if (config.histogram) {
    result['metric:fmp:histogramBuckets'] = getHistogram(data.isInitial, BMPageSegmentLoadMetrics.fmp, config.histogram);
    result['metric:tti:histogramBuckets'] = getHistogram(data.isInitial, BMPageSegmentLoadMetrics.tti, config.histogram);
  } else if (globalConfig.histogram) {
    var histogram = globalConfig.histogram;
    result['metric:fmp:histogramBuckets'] = getHistogram(data.isInitial, BMPageSegmentLoadMetrics.fmp, histogram);
    result['metric:tti:histogramBuckets'] = getHistogram(data.isInitial, BMPageSegmentLoadMetrics.tti, histogram);
  }
  if ((_config$slo = config.slo) !== null && _config$slo !== void 0 && _config$slo.fmp) {
    var threshold = getThreshold(data.isInitial, config.slo.fmp);
    result['metric:fmp:slo'] = result['metric:fmp'] <= threshold;
    result['metric:fmp:slo:threshold'] = threshold;
  }
  if ((_config$slo2 = config.slo) !== null && _config$slo2 !== void 0 && _config$slo2.tti) {
    var _threshold = getThreshold(data.isInitial, config.slo.tti);
    result['metric:tti:slo'] = result['metric:tti'] <= _threshold;
    result['metric:tti:slo:threshold'] = _threshold;
  }
  if (config.includePageLoadTimings === PageSegmentPageLoadMetricsOptions.IF_PRESENT || config.includePageLoadTimings === PageSegmentPageLoadMetricsOptions.WAIT_UNTIL_PRESENT) {
    var pageLoadData = pageLoadMetaMetric.getData();
    var _fmp = pageLoadData.stop !== null && pageLoadData.start !== null ? Math.round((pageLoadData.marks[BMPageLoadMetrics.fmp] || pageLoadData.stop) - pageLoadData.start) : null;
    var tti = pageLoadData.stop !== null && pageLoadData.start !== null ? Math.round(pageLoadData.stop - pageLoadData.start) : null;
    result['pageLoad'] = {
      fmp: _fmp,
      tti: tti
    };
  }
  return result;
};