import { Action } from '../types';

export interface Session {
  sessionId: string;
  accountId: string;
  email: string;
  displayName?: string;
  avatarUrl?: string;
}

export interface Sessions {
  current: Session;
  associated: Session[];
}

export interface State {
  sessions: Sessions | null;
  isLoading?: boolean;
}

export const defaultState: State = {
  sessions: null,
};

const selectAccount = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case 'SESSION_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
};

export default selectAccount;
