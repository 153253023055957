import React from 'react';
import { sizes } from '@atlaskit/logo/constants';
import logoSrc from '../../../images/cobranding/atlas/atlaslogo.svg';
import styled from '@emotion/styled';
import { LogoSize } from '../shared-cobranding';

const LogoContainer = styled.div<{ size: LogoSize }>`
  display: inline-block;
  height: ${p => (p.size ? sizes[p.size] : sizes['large'])}px;
  position: relative;
  user-select: none;

  > img {
    width: 100%;
    height: 48px;
  }
`;

export interface AtlasLogoProps {
  size: LogoSize;
}

const AtlasLogo = ({ size }: AtlasLogoProps) => {
  return (
    <LogoContainer size={size}>
      <img src={logoSrc} alt={'Atlas Logo'} />
    </LogoContainer>
  );
};

export default AtlasLogo;
