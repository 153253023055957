import { Action, ResetPasswordFieldError, UnforcedResetPasswordErrorCode } from '../types';

export interface State {
  error: UnforcedResetPasswordErrorCode | undefined;
  fieldErrors: ResetPasswordFieldError;
  isLoading: boolean;
  isSent: boolean;
  redirectTo?: string;
}

export const defaultState: State = {
  error: undefined,
  fieldErrors: {},
  isLoading: false,
  isSent: false,
};

const resetPassword = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case 'RESET_PASSWORD_RESET':
      return {
        ...state,
        error: undefined,
        isLoading: false,
        isSent: false,
      };
    case 'RESET_PASSWORD_REQUEST_LINK':
      return {
        ...state,
        error: undefined,
        isLoading: true,
        isSent: false,
      };
    case 'RESET_PASSWORD_REQUEST_LINK_SUCCESS':
      return {
        ...state,
        error: undefined,
        isLoading: false,
        isSent: true,
      };
    // TODO: Clean up legacy https://hello.jira.atlassian.cloud/browse/KIRBY-6709
    case 'RESET_PASSWORD_REQUEST_LINK_REDIRECT':
      return {
        ...state,
        error: undefined,
        isLoading: true,
        isSent: false,
        redirectTo: action.redirectTo,
      };
    case 'RESET_PASSWORD_ERROR':
      return {
        ...state,
        error: action.error,
        isLoading: false,
        isSent: false,
      };
    case 'RESET_PASSWORD_REQUEST_LINK_FAILED_WITH_FIELD_ERRORS':
      return {
        ...state,
        fieldErrors: action.fieldErrors,
        isLoading: false,
        isSent: false,
      };
    default:
      return state;
  }
};

export default resetPassword;
