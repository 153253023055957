import styled from '@emotion/styled';
import Button, { LoadingButtonProps } from '@atlaskit/button/loading-button';
import React from 'react';

const StyledButton = styled(Button)`
  height: 40px !important;
  line-height: 40px !important;

  div,
  span {
    display: flex !important;
    justify-content: center;
  }
`;

export interface TallButtonProps extends LoadingButtonProps {
  isFullWidth?: boolean;
}

export default function TallButton(props: TallButtonProps) {
  const { isFullWidth, ...rest } = props;
  return <StyledButton {...rest} shouldFitContainer={isFullWidth} />;
}
