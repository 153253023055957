import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { colors, gridSize } from '@atlaskit/theme';

interface ProductHeadingProps {
  suffix: ReactNode;
  children?: ReactNode;
}

export const Suffix = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${gridSize() * 3}px;
  align-items: center;

  h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${colors.N800};
  }
`;

const ProductHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: ${gridSize() * 2}px;
`;

export const ProductHeading = ({ suffix, children }: ProductHeadingProps) => {
  return (
    <ProductHeadingWrapper data-testid="header" id="ProductHeading">
      {children}
      <Suffix data-testid="header-suffix" id="ProductHeadingSuffix">
        {suffix}
      </Suffix>
    </ProductHeadingWrapper>
  );
};
