const userAgent = navigator.userAgent || navigator.vendor || window.opera;

export type Platform = 'android' | 'ios' | 'windows phone' | 'unknown';

/**
 * ios, android, windows phone or unknown
 */
export const detectPlatform = (): Platform => {
  if (/windows phone/i.test(userAgent)) {
    return 'windows phone';
  }
  if (/android/i.test(userAgent)) {
    return 'android';
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }
  return 'unknown';
};

export interface Client {
  platform: Platform;
  atlassianMobileApp: boolean;
}

export const detectClient = (): Client => {
  return {
    platform: detectPlatform(),
    atlassianMobileApp: /AtlassianMobileApp/i.test(userAgent),
  };
};
