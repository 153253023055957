import { Action, SignupErrorCode, SignupFieldError } from '../types';

export interface State {
  error: SignupErrorCode | null;
  fieldErrors: SignupFieldError;
  isLoading: boolean;
  redirectTo: string | null;
  autoSubmit: boolean;
}

export const defaultState: State = {
  error: null,
  fieldErrors: {},
  isLoading: false,
  redirectTo: null,
  autoSubmit: false,
};

const signup = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case 'SIGNUP_RESET':
      return defaultState;
    case 'SIGNUP_REQUEST':
      return {
        ...state,
        error: null,
        fieldErrors: {},
        isLoading: true,
      };
    case 'SIGNUP_REQUEST_SUCCESS':
      return {
        ...state,
        isLoading: false,
        fieldErrors: {},
        redirectTo: action.redirectTo,
      };
    case 'SIGNUP_REQUEST_FAILED':
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case 'SIGNUP_SHOW_FIELD_ERRORS':
      return {
        ...state,
        fieldErrors: action.errors,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default signup;
