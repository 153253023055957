import React from 'react';
import { FormattedMessage } from 'react-intl';

import { isMobileOidc } from '../utilities/oidc/oidc';
import ErrorPage from '../components/ErrorPage';
import { useAppSelector } from '../stores/redux.store';

const isUnSupportedMobileBrowser = () => {
  return /UC.*Browser|UCWEB/.test(window.navigator.userAgent);
};

export const UnSupportedMobileBrowserInterceptor: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const oidcContext = useAppSelector(state => state.microbranding.oidcContext);

  if (isMobileOidc(oidcContext) && isUnSupportedMobileBrowser()) {
    return (
      <ErrorPage
        title={
          <FormattedMessage
            id="unsupported.mobile.browser.error.message.heading"
            defaultMessage="We’re unable to log you in"
          />
        }>
        <FormattedMessage
          id="unsupported.mobile.browser.error.message.content"
          defaultMessage="You are currently using an unsupported browser. Please use a supported browser such as Chrome, Firefox, Opera or Samsung Internet."
        />
      </ErrorPage>
    );
  }
  return <>{children}</>;
};

export default UnSupportedMobileBrowserInterceptor;
