import React from 'react';
import { AtlassianLogo, JiraLogo } from '@atlaskit/logo';

import { CobrandingComponents } from '../shared-cobranding';
import { BlankBackground, IdentityWrapper } from './common/styled';

function BlankBackgroundWithLogo({ children }: { children: React.ReactNode }) {
  return (
    <BlankBackground>
      <JiraLogo appearance="brand" size="large" />
      <IdentityWrapper>{children}</IdentityWrapper>
      <AtlassianLogo appearance="brand" size="small" />
    </BlankBackground>
  );
}

const WacSignupOTPExperimentJira: CobrandingComponents = {
  background: BlankBackgroundWithLogo,
  logo: null,
  whiteboxWidth: '400px',
  hideWhiteboxBorder: true,
};

export default WacSignupOTPExperimentJira;
