import React from 'react';
import styled from '@emotion/styled';
import { media } from '../../utilities/style-utils';
import { FormattedMessage } from 'react-intl';
import { colors, gridSize } from '@atlaskit/theme';
import { AtlassianLogo } from '@atlaskit/logo';
import { linkStyles, listStyles } from './shared-cobranding';
import { LearnMoreAboutAtlassianAccountLink } from '../links';
import { useButtonClickedEvent } from '../../utilities/analytics/analytics-web-client';

const PageFooter = styled.footer<{ textColor: string; footerMessageTextColor: string }>`
  background: transparent;
  display: flex;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  flex-direction: column;
  margin: 0 auto;
  border-top: 1px solid ${colors.N50};
  padding-top: ${gridSize() * 3}px;
  font-size: 11px;
  color: ${props => props.footerMessageTextColor};
  ${props => linkStyles(props.textColor)}
  ${listStyles}

  ${media.handheld`
     line-height: inherit;
     background-color: transparent;
  `}
`;

const SubText = styled.div`
  padding-top: ${gridSize()}px;
`;
export interface OwnProps {
  pageId: string;
  renderText: boolean;
  footerProducts: string;
}

const FooterComponent = ({ renderText, footerProducts, pageId }: OwnProps) => {
  const onLinkClicked = useButtonClickedEvent(pageId, 'learnMoreAtlassianAccount');

  return (
    <PageFooter
      data-testid="page-footer"
      textColor={colors.B400}
      footerMessageTextColor={colors.N800}>
      <AtlassianLogo appearance="neutral" size="small" />
      {renderText && (
        <SubText>
          <FormattedMessage
            id="footer.cobranding.text"
            defaultMessage="One account for {products} and {link}."
            description="{products} is a comma-separated list of products. {link} is a hyperlink that will be filled with a separately translated string: more"
            values={{
              products: footerProducts,
              link: <LearnMoreAboutAtlassianAccountLink onClick={onLinkClicked} />,
            }}
          />
        </SubText>
      )}
    </PageFooter>
  );
};

export default FooterComponent;
