import React from 'react';
import styled from '@emotion/styled';

import { media } from '../../utilities/style-utils';
import { colorPalette } from '../../utilities/color-palette';
import { colors, gridSize } from '@atlaskit/theme';
import { LogoProps } from '@atlaskit/logo/constants';

export const whiteboxWidthDesktop = `${gridSize() * 50}px`;
export const whiteboxWidthHandheld = `${gridSize() * 40}px`;

export const DesktopLogo = styled.div`
  ${media.handheld`
    display: none;
  `};
`;

export const MobileLogo = styled.div`
  display: none;

  ${media.handheld`
    display: inline-block;
  `};
`;

export const linkStyles = (color: string) =>
  `
   a {
      color: ${color};

      &:hover {
        color: ${color};
      }
      &.hover {
        color: ${color};
      }
      &.primary-action {
        color: ${color};
      }
   }
`;

export const listStyles = `
    ul {
      display: flex;
      justify-content: center;
      list-style: none;
      padding: 0;
    }
`;

export const blueLogo: CobrandingLogoStyles = {
  textColor: colors.B400,
  iconColor: colors.B200,
  iconGradientStart: colors.B400,
};

export const darkLogo: CobrandingLogoStyles = {
  textColor: colors.N500,
  iconColor: colors.N300,
  iconGradientStart: colors.N500,
};

export type CobrandingLogo = CobrandingAtlaskitLogo | CobrandingCustomLogo;

export interface CobrandingAtlaskitLogo {
  component: React.FunctionComponent<LogoProps>;
}

export interface CobrandingCustomLogo {
  renderLogo: () => React.ReactChild;
}

export interface CobrandingComponents {
  background: React.FunctionComponent<{ children?: React.ReactNode }>;
  logo: CobrandingLogo | null;
  footerProducts?: string; // comma-separated list of products with the current product listed first
  whiteboxWidth?: string;
  hideWhiteboxBorder?: boolean;
}

export type LogoSize = LogoProps['size'];

/*
 * From atlaskit docs:
 * iconGradientStop should usually match iconColor to avoid
 * rendering issues in some browsers such as Safari.
 * so we removed it completely to avoid possible mistakes
 */
export type CobrandingLogoStyles = {
  textColor: string;
  iconColor: string;
  iconGradientStart?: string;
  size?: LogoSize;
};

export const DefaultBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: ${colors.N10};
  ${media.handheld`
    background: ${colorPalette.primaryWhite};
    color: ${colorPalette.primaryNavy};
  `}
`;

export function backgroundWithSideImages(
  leftImageSrc: string,
  rightImageSrc: string
): React.FunctionComponent {
  const scalingImagesMaxWidth = `${gridSize() * 150}px`; //50 grid size for whitebox + 2 * 50 grid size for images
  const widescreenBackgroundImageSize = `calc((100vw - ${whiteboxWidthDesktop})/2 - ${
    gridSize() * 4
  }px) auto`;
  return styled(DefaultBackground)`
    background-image: url(${leftImageSrc}), url(${rightImageSrc});
    background-repeat: no-repeat, no-repeat;
    background-attachment: fixed, fixed;
    background-size: ${gridSize() * 46}px auto, ${gridSize() * 46}px auto;
    background-position: left bottom, right bottom;

    @media (max-width: ${scalingImagesMaxWidth}) {
      background-size: ${widescreenBackgroundImageSize}, ${widescreenBackgroundImageSize}, cover;
    }

    ${media.handheld`
      background: ${colorPalette.primaryWhite};
      color: ${colorPalette.primaryNavy};
    `}
  `;
}

export function backgroundWithImage(bgImageSrc: string): React.FunctionComponent {
  return styled(DefaultBackground)`
    background-image: url(${bgImageSrc});
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;

    ${media.handheld`
      background: ${colorPalette.primaryWhite};
      color: ${colorPalette.primaryNavy};
    `}
  `;
}

export const renderProductLogo = (props: CobrandingLogo) => {
  if ('renderLogo' in props) {
    // is a custom logo
    return props.renderLogo();
  }

  const { component: AtlaskitLogo } = props;
  return <AtlaskitLogo appearance="brand" size="large" />;
};
