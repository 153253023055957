import defaultImageLeft from '../../../images/cobranding/default/default_left.svg';
import defaultImageRight from '../../../images/cobranding/default/default_right.svg';
import { CompassLogo } from '@atlaskit/logo';
import { backgroundWithSideImages, CobrandingComponents } from '../shared-cobranding';

export const CompassBackground = backgroundWithSideImages(defaultImageLeft, defaultImageRight);

const CompassCobranding: CobrandingComponents = {
  background: CompassBackground,
  logo: { component: CompassLogo },
  footerProducts: 'Trello, Jira, Confluence',
};

export default CompassCobranding;
