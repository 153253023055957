import {
  Action,
  KnownRecoveryEmailErrorReason,
  KnownRecoveryEmailErrorReasons,
  MultiFactorOTPError,
  MultiFactorRecoveryError,
  RecoveryEmailErrorReason,
  ThunkAction,
} from '../types';
import { AnalyticsClient } from '../utilities/analytics/analytics-web-client';
import { idacOrigin } from '../utilities/env';
import { NavigateFunction } from 'react-router-dom';
import { captureException } from '../utilities/analytics/error-reporting';

export const confirmedRecoveryCode = (
  params: string,
  analyticsClient: AnalyticsClient
): Action => ({
  type: 'MFA_VERIFICATION_CONFIRMED_RECOVER',
  params,
  analyticsClient,
});

export const verifyOtpCode = (
  params: string,
  otpCode: string,
  analyticsClient: AnalyticsClient
): Action => ({
  type: 'MFA_VERIFICATION_VERIFY',
  params,
  otpCode,
  analyticsClient,
});

export const resendOtp = (
  params: string,
  analyticsClient: AnalyticsClient,
  navigate: NavigateFunction,
  mfaMethod?: string
): Action => ({
  type: 'MFA_VERIFICATION_RESEND_OTP',
  params,
  analyticsClient,
  navigate,
  mfaMethod,
});

export const recoverAccount = (
  params: string,
  recoveryCode: string,
  analyticsClient: AnalyticsClient,
  navigate: NavigateFunction
): Action => ({
  type: 'MFA_VERIFICATION_RECOVER',
  params,
  recoveryCode,
  analyticsClient,
  navigate,
});

export const mfaRecoverResponse = ({ recoveryCode }: { recoveryCode: string }): Action => ({
  type: 'MFA_VERIFICATION_RECOVER_RESPONSE',
  recoveryCode,
});

export const mfaVerifyError = ({ errorCode, message }: MultiFactorOTPError): Action => ({
  type: 'MFA_VERIFICATION_VERIFY_ERROR',
  errorCode,
  message,
});

export const mfaRecoverError = ({ errorCode, message }: MultiFactorRecoveryError): Action => ({
  type: 'MFA_VERIFICATION_RECOVER_ERROR',
  errorCode,
  message,
});

export const recoveryEmailSent = (): Action => ({
  type: 'RECOVERY_EMAIL_SENT',
});

export const recoveryEmailStart = (): Action => ({
  type: 'RECOVERY_EMAIL_START',
});

export const recoveryEmailError = (reason: RecoveryEmailErrorReason): Action => ({
  type: 'RECOVERY_EMAIL_ERROR',
  reason,
});

export const sendRecoveryEmail = (email: string): ThunkAction => async dispatch => {
  dispatch(recoveryEmailStart());

  try {
    const response = await fetch(`${idacOrigin}/rest/tsv-recovery/trigger`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    });

    const body = await response.json();

    const errorReason = body?.error?.reason;

    // Note that sing-in-service may return an error reason with a 200 response, in cases where
    // a non-200 response could introduce an account enumeration vulnerability.
    if (response.ok && !errorReason) {
      dispatch(recoveryEmailSent());
    } else {
      const narrowedReason = narrowErrorReason(errorReason);
      dispatch(recoveryEmailError(narrowedReason));
    }
  } catch (e) {
    captureException(e);
    dispatch(recoveryEmailError('unknown'));
  }
};

const isKnownRecoveryEmailErrorReason = (reason: string): reason is KnownRecoveryEmailErrorReason =>
  KnownRecoveryEmailErrorReasons.includes(reason as KnownRecoveryEmailErrorReason);

/** Maps arbitrary string returned by API to a type-safe value compatible with the redux state */
const narrowErrorReason = (reason: string | undefined) => {
  if (reason && isKnownRecoveryEmailErrorReason(reason)) {
    return reason;
  }

  return 'unknown';
};
