import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { captureErrorMessage } from '../utilities/analytics/error-reporting';
import { useAppSelector } from '../stores/redux.store';

type Props = {
  children?: React.ReactNode;
};

/**
 * We have this divergence since we use Webpack for Jest testing and Parcel for dev/prod.
 * Parcel dynamic import returns: obj
 * Webpack dynamic import returns: { default: obj }
 * Why can't we use Parcel for Jest? See: https://parceljs.org/languages/javascript/#usage-with-other-tools
 */
type ImportedMessages =
  | Promise<Record<string, string>>
  | Promise<{ default: Record<string, string> }>;
const languages: Record<string, () => ImportedMessages> = {
  'pt-BR': () => import('../../translations/pt-BR.json'),
  'en-ZZ': () => import('../../translations/en-ZZ.json'),
  'en-GB': () => import('../../translations/en-GB.json'),
  'zh-CN': () => import('../../translations/zh-CN.json'),
  'zh-TW': () => import('../../translations/zh-TW.json'),
  cs: () => import('../../translations/cs-CZ.json'),
  da: () => import('../../translations/da-DK.json'),
  de: () => import('../../translations/de-DE.json'),
  en: () => import('../../translations/en.json'),
  es: () => import('../../translations/es.json'),
  fi: () => import('../../translations/fi-FI.json'),
  fr: () => import('../../translations/fr-FR.json'),
  hu: () => import('../../translations/hu-HU.json'),
  it: () => import('../../translations/it-IT.json'),
  ja: () => import('../../translations/ja-JP.json'),
  ko: () => import('../../translations/ko-KR.json'),
  nb: () => import('../../translations/nb-NO.json'),
  nl: () => import('../../translations/nl-NL.json'),
  pl: () => import('../../translations/pl-PL.json'),
  ru: () => import('../../translations/ru-RU.json'),
  sv: () => import('../../translations/sv-SE.json'),
  th: () => import('../../translations/th-TH.json'),
  tr: () => import('../../translations/tr-TR.json'),
  uk: () => import('../../translations/uk-UA.json'),
  vi: () => import('../../translations/vi-VN.json'),
};

const fetchLanguage = async (locale: string) => {
  if (locale in languages) {
    const messages = await languages[locale]();
    return { locale, messages: messages.default || messages };
  }

  throw new Error(`${locale} not found in languages config`);
};

const LocaleSwitcher = ({ children }: Props) => {
  const locale = useAppSelector(state => state.locale);
  const [fallbackLocale] = locale.split('-', 1);

  const [{ loading, messages, derivedLocale }, setState] = useState({
    loading: true,
    messages: {},
    derivedLocale: locale,
  });

  useEffect(() => {
    fetchLanguage(locale)
      .catch(() => fetchLanguage(fallbackLocale))
      .then(
        i18n => {
          setState({ loading: false, messages: i18n.messages, derivedLocale: i18n.locale });
        },
        () => {
          const content = `no translations were found for localeId ${locale} or fallbackLocale ${fallbackLocale}.`;
          console.debug(content);
          captureErrorMessage('i18n_fetch_language_error', { content, locale, fallbackLocale });
          setState(prevState => ({ ...prevState, loading: false }));
        }
      );
  }, [locale, fallbackLocale]);

  return (
    <IntlProvider locale={derivedLocale} messages={messages}>
      {loading ? <></> : children}
    </IntlProvider>
  );
};

export default LocaleSwitcher;
