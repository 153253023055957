export enum AidFrontendFeatureGate {
  // https://switcheroo.atlassian.com/ui/gates/d27e505d-7979-40e3-bae2-e8820d6a1b4d/key/identity-sign-in-service-use-castle-on-signup
  SHOULD_USE_CASTLE_ON_SIGNUP = 'identity-sign-in-service-use-castle-on-signup',
  // https://switcheroo.atlassian.com/ui/gates/e7ac1711-ad22-4973-bc22-fa2ea03821e0/key/_identity_aid_frontend_use_global_page_state
  SHOULD_USE_GLOBAL_PAGE_STATE = 'identity-aid-frontend-use-global-page-state',
  // https://switcheroo.atlassian.com/ui/gates/747daf81-87f7-46dc-8ce3-c9e229c6c60f/key/should_fail_open_on_recaptcha_aid-frontend
  SHOULD_FAIL_OPEN_RECAPTCHA = 'should_fail_open_on_recaptcha_aid-frontend',
  // TODO remove after resolving HOT-113002
  // https://switcheroo.atlassian.com/ui/gates/b62923fa-9ad8-4aa4-9c00-f265ea08db0d/key/hot-113002_force_checkbox_recaptcha
  HOT_113002_FORCE_CHECKBOX = 'hot-113002_force_checkbox_recaptcha',
}
