const EMPTY_APP_STATE_VALUE = '{{data_app_state}}';

const hasNoInjectedServerState = () => {
  const data = document.body.getAttribute('data-app-state');
  const dataNotInjectedDueToTestContext = data === null;

  return data === EMPTY_APP_STATE_VALUE || dataNotInjectedDueToTestContext;
};

export default hasNoInjectedServerState;
