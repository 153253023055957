import { Action, MfaFlow, MultiFactorStartSmsEnrollError } from '../types';
import { UnsupportedCountry } from '../data/countries';

export interface State {
  emailAddress?: string;
  invalid: boolean;
  recoveryCode: string | null;
  redirectUrl?: string | null;
  token: string | null;
  totpSecret?: string;
  transactionToken: string | null;
  totpUri?: string;
  enrollStarted: boolean;
  enrollConfirmed: boolean;
  smsEnrollError?: MultiFactorStartSmsEnrollError | null;
  phoneNumber?: string;
  confirmingEnroll?: boolean;
  unsupportedCountries: UnsupportedCountry[];
  mfaFlow?: MfaFlow;
}

export const defaultState: State = {
  invalid: false,
  enrollStarted: false,
  enrollConfirmed: false,
  token: '',
  redirectUrl: null,
  recoveryCode: null,
  transactionToken: null,
  unsupportedCountries: [],
};

export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case 'START_ENROLL':
      return {
        ...state,
        token: action.token,
        mfaFlow: MfaFlow.Totp,
      };
    case 'START_ENROLL_RESPONSE':
      return {
        ...state,
        enrollStarted: true,
        transactionToken: action.transactionToken,
        emailAddress: action.emailAddress,
        totpSecret: action.totpSecret,
        recoveryCode: action.recoveryCode,
        totpUri: action.totpUri,
      };
    case 'CONFIRM_ENROLL':
      return {
        ...state,
        confirmingEnroll: true,
        invalid: false,
      };
    case 'CONFIRM_ENROLL_RESPONSE':
      return {
        ...state,
        enrollConfirmed: true,
        confirmingEnroll: false,
        redirectUrl: action.redirectUrl,
      };
    case 'CONFIRM_ENROLL_ERROR':
      return {
        ...state,
        confirmingEnroll: false,
        invalid: true,
      };
    case 'START_SMS_ENROLL':
      return {
        ...state,
        token: action.token,
        phoneNumber: action.phoneNumber,
        mfaFlow: MfaFlow.Sms,
      };
    case 'START_SMS_ENROLL_RESPONSE':
      return {
        ...state,
        transactionToken: action.transactionToken,
        recoveryCode: action.recoveryCode,
        smsEnrollError: null,
        phoneNumber: action.phoneNumber,
      };
    case 'START_SMS_ENROLL_ERROR':
      return {
        ...state,
        smsEnrollError: action.smsEnrollError,
        transactionToken: null,
        recoveryCode: null,
      };
    case 'UPDATE_SMS_ENROLLMENT_UNSUPPORTED_COUNTRIES':
      return {
        ...state,
        unsupportedCountries: action.unsupportedCountries,
      };
    case 'RESET_SMS_ENROLL_ERROR':
      return {
        ...state,
        smsEnrollError: null,
      };
    case 'START_SECURITY_KEY_ENROLL':
      return {
        ...state,
        token: action.token,
        mfaFlow: MfaFlow.SecurityKey,
      };
    case 'CONFIRM_SECURITY_KEY_ENROLLMENT':
      return {
        ...state,
        recoveryCode: action.recoveryCode,
      };
    default:
      return state;
  }
};
