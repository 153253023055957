import type { Identifiers } from '@atlaskit/feature-gate-js-client';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../stores/redux.store';
import { getFfsId } from '../utilities/feature-flags';
import { FeatureGatesInit } from './FeatureGatesInit';
import { useAnalyticsAnonymousId } from '../utilities/analytics/analytics-web-client';
import { ConfigCollection } from '@atlaskit/web-config-client';
import Spinner from '@atlaskit/spinner';
import { useIntl } from 'react-intl';
import {
  DynamicConfigsContext,
  initializeDynamicConfigs,
} from '../utilities/feature-flags/dynamic-config';

type FeatureGateProviderProps = {
  children: ReactNode;
};

export const FeatureGateProvider = ({ children }: FeatureGateProviderProps) => {
  const [isFeatureGatesClientReady, setIsFeatureGatesClientReady] = useState(false);
  const [dynamicConfigsClient, setDynamicConfigsCollection] = useState<
    ConfigCollection | null | undefined
  >(undefined);

  const tenantId = useAppSelector(state => state.context?.tenantCloudId);
  const atlassianAccountId = useAppSelector(state => state.context?.userId);
  const ffsId = getFfsId();
  const { formatMessage } = useIntl();
  const analyticsAnonymousId = useAnalyticsAnonymousId();

  const featureGatesIdentifiers: Identifiers = useMemo(
    () => ({
      tenantId,
      atlassianAccountId,
      randomizationId: ffsId,
      analyticsAnonymousId,
    }),
    [tenantId, atlassianAccountId, ffsId, analyticsAnonymousId]
  );

  useEffect(() => {
    initializeDynamicConfigs(analyticsAnonymousId).then(dynamicConfigs =>
      setDynamicConfigsCollection(dynamicConfigs)
    );
  }, [analyticsAnonymousId]);

  const onFeatureGatesReady = useCallback(() => setIsFeatureGatesClientReady(true), []);

  const areClientsReady = isFeatureGatesClientReady && !!dynamicConfigsClient;

  return (
    <>
      <FeatureGatesInit identifiers={featureGatesIdentifiers} onReady={onFeatureGatesReady} />

      {!areClientsReady && (
        <Spinner
          label={formatMessage({
            id: 'statsig.clients.loading',
            defaultMessage: 'Loading feature flags...',
            description:
              'Label text for loading spinner when Statsig clients are being initialized',
          })}
        />
      )}

      {areClientsReady && (
        <DynamicConfigsContext.Provider value={dynamicConfigsClient}>
          {children}
        </DynamicConfigsContext.Provider>
      )}
    </>
  );
};
