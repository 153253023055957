import { Action, RecoveryTsvErrorCode, TsvRecoveryFieldError } from '../types';

export interface State {
  error: RecoveryTsvErrorCode | null;
  fieldErrors: TsvRecoveryFieldError;
  redirectTo: string | null;
  isLoading: boolean;
}

export const defaultState: State = {
  error: null,
  fieldErrors: {},
  redirectTo: null,
  isLoading: false,
};

const recovery = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case 'TSV_RECOVERY_RESET':
      return defaultState;

    case 'TSV_RECOVERY_LOGIN_REQUEST_LINK_REDIRECT':
      return {
        ...state,
        fieldErrors: {},
        error: null,
        redirectTo: action.redirectTo,
        isLoading: true,
      };

    case 'TSV_RECOVERY_REQUEST_FAILED':
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    case 'TSV_RECOVERY_SHOW_FIELD_ERRORS':
      return {
        ...state,
        fieldErrors: action.error,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default recovery;
