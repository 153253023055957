export type ConsentType = 'fedramp' | 'privacy';

export interface State {
  organisationId?: string;
  consentType?: ConsentType;
}

export const defaultState: State = {
  consentType: undefined,
};

const loginConsent = (state: State = defaultState): State => {
  return state;
};

export default loginConsent;
