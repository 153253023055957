import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import ErrorPage from '../components/ErrorPage';
import CsrfTokenMismatchErrorPage from './CsrfTokenMismatchErrorPage';
import messages from './ServerErrorInterceptor.messages';
import { useAppSelector } from '../stores/redux.store';

export const ServerErrorInterceptor: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const intl = useIntl();
  const serverError = useAppSelector(state => state.serverError);

  if (serverError) {
    if (serverError.type === 'not_found') {
      return <ErrorPage title={intl.formatMessage(messages.notFoundTitle)} />;
    } else if (serverError.type === 'internal_server_error') {
      return (
        <ErrorPage title={intl.formatMessage(messages.internalServerErrorTitle)}>
          <FormattedMessage {...messages.internalServerErrorDescription} />
        </ErrorPage>
      );
    } else if (serverError.type === 'bad_request') {
      return (
        <ErrorPage title={intl.formatMessage(messages.badRequestTitle)}>
          <FormattedMessage
            id="error.badrequest.desc.xml"
            defaultMessage="Often, clearing your browsers cache and restarting your browser will solve this problem.{br}If that doesn't work, please <support-link>contact support</support-link>."
            values={{
              br: <br />,
              'support-link': chunks => (
                <a href="https://support.atlassian.com/" target="_blank" rel="noreferrer">
                  {chunks}
                </a>
              ),
            }}
          />
        </ErrorPage>
      );
    } else if (serverError.type === 'csrf_token_mismatch') {
      return <CsrfTokenMismatchErrorPage />;
    } else {
      throw new Error(serverError.type || 'ServerError');
    }
  }

  return <>{children}</>;
};

export default ServerErrorInterceptor;
