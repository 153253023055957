import _typeof from "@babel/runtime/helpers/typeof";
import jsonStringfy from 'fast-json-stable-stringify';
import uuid from 'uuid/v4';
import { ReadyReason } from '../api/types';
import { EnvironmentType, EvaluationErrorKind, EvaluationReason } from '../index';
import getLogger from '../logger';
import cloneObject from '../util/clone-object';
import { RefreshStatus } from './types';
export function featureFlagToRawFlag(featureFlag) {
  var rawFlag = {
    value: featureFlag.value,
    trackingInfo: featureFlag.trackingInfo || {
      samplingRate: 0
    }
  };
  if (featureFlag.reason) {
    rawFlag.evaluationDetail = {
      reason: featureFlag.reason,
      ruleId: featureFlag.ruleId
    };
  }
  return rawFlag;
}
export function flagResponseToFlagUpdate(response) {
  var _response$featureFlag;
  var flags = {};
  (_response$featureFlag = response.featureFlagValues) === null || _response$featureFlag === void 0 || _response$featureFlag.forEach(function (featureFlag) {
    flags[featureFlag.key] = featureFlagToRawFlag(featureFlag);
  });
  return {
    flags: flags,
    deletedFlags: response.deletedFlags || [],
    versionData: response.versionData
  };
}
export function flagStateToFlagUpdate(currentFlags, newFlagState) {
  var newFlags = (newFlagState === null || newFlagState === void 0 ? void 0 : newFlagState.flags) || {};
  var deletedFlags = Object.keys(currentFlags).filter(function (flagKey) {
    return newFlags[flagKey] === undefined;
  });
  var versionData = getVersionData(newFlagState);
  return {
    flags: newFlags,
    deletedFlags: deletedFlags,
    versionData: versionData
  };
}
var getVersionData = function getVersionData(flagState) {
  if ('version' in flagState) {
    return flagState.version;
  } else if ('versionData' in flagState) {
    return flagState.versionData;
  }
  return undefined;
};
export function hasFlagValueChanged(oldFlag, newFlag) {
  return jsonStringfy(oldFlag === null || oldFlag === void 0 ? void 0 : oldFlag.value) !== jsonStringfy(newFlag === null || newFlag === void 0 ? void 0 : newFlag.value);
}
export function refreshStatusToEvaluationErrorKind(status) {
  switch (status) {
    case RefreshStatus.INITIALISED:
      return EvaluationErrorKind.CLIENT_NOT_READY;
    case RefreshStatus.ERROR:
      return EvaluationErrorKind.SERVER_ERROR;
    case RefreshStatus.SUCCESS:
      return EvaluationErrorKind.FLAG_NOT_FOUND;
    default:
      return EvaluationErrorKind.OTHER;
  }
}
export function validateFlag(flag, defaultValue, refreshStatus, options) {
  var _options$oneOf;
  if (!flag) {
    return {
      value: defaultValue,
      evaluationDetail: {
        reason: EvaluationReason.ERROR,
        errorKind: refreshStatusToEvaluationErrorKind(refreshStatus)
      }
    };
  }
  if (_typeof(flag.value) !== _typeof(defaultValue)) {
    return {
      value: defaultValue,
      trackingInfo: flag.trackingInfo,
      evaluationDetail: {
        reason: EvaluationReason.ERROR,
        errorKind: EvaluationErrorKind.WRONG_TYPE
      }
    };
  }
  if (options !== null && options !== void 0 && (_options$oneOf = options.oneOf) !== null && _options$oneOf !== void 0 && _options$oneOf.length && options.oneOf.indexOf(flag.value) < 0) {
    return {
      value: defaultValue,
      trackingInfo: flag.trackingInfo,
      evaluationDetail: {
        reason: EvaluationReason.ERROR,
        errorKind: EvaluationErrorKind.VALIDATION_ERROR
      }
    };
  }
  return cloneObject(flag);
}
export function isBlank(str) {
  return !str || /^\s*$/.test(str);
}
export function isFunction(func) {
  return typeof func === 'function';
}
export function validateConstructorArgs(apiKey, analyticsWebClient, featureFlagUser, options) {
  if (isBlank(apiKey)) {
    throw new Error('apiKey is missing');
  }
  if (!isFunction(analyticsWebClient === null || analyticsWebClient === void 0 ? void 0 : analyticsWebClient.sendOperationalEvent)) {
    throw new Error('analyticsWebClient.sendOperationalEvent is missing or not a function');
  }
  if (!featureFlagUser) {
    throw new Error('featureFlagUser is missing');
  }
  if (isBlank(options === null || options === void 0 ? void 0 : options.productKey)) {
    throw new Error('options.productKey is missing');
  }
  if (Object.values(EnvironmentType).indexOf(options === null || options === void 0 ? void 0 : options.environment) === -1) {
    throw new Error('options.environment is missing or invalid');
  }
  if (options.pollingInterval !== undefined && options.pollingInterval !== null) {
    var prodMinInterval = 60000;
    var nonProdMinInterval = 1000;
    if (options.pollingInterval <= prodMinInterval) {
      if (options.environment === EnvironmentType.PROD) {
        // eslint-disable-next-line no-param-reassign
        options.pollingInterval = prodMinInterval;
        getLogger().log("options.pollingInterval needs to be greater than ".concat(prodMinInterval));
      } else if (options.pollingInterval <= nonProdMinInterval) {
        throw new Error("options.pollingInterval needs to be greater than ".concat(nonProdMinInterval));
      } else {
        getLogger().log("options.pollingInterval needs to be greater than ".concat(prodMinInterval, " in Production"));
      }
    }
  }
}
export function validateDefaultValue(value) {
  if (value === undefined || value === null) {
    throw new Error('defaultValue is missing');
  }
}

/* eslint-disable no-bitwise */
export function uuidv4() {
  return uuid();
}
/* eslint-enable no-bitwise */

export function getReadyMessageForStatusCode(statusCode) {
  switch (statusCode) {
    case 0:
      return 'Request took too long to finish, client aborted the request.';
    case 400:
      return 'Service returned bad request response code. The version data maybe malformed or out of sync with the server.';
    case 401:
      return 'Service returned unauthorized response code. Ensure that the API token is valid for this environment.';
    case 429:
      return 'Service rate limit exceeded.';
    case 500:
    case 502:
    case 503:
    case 504:
      return "Service is unavailable, status code: ".concat(statusCode);
    default:
      return "Unsure what went wrong, status code: ".concat(statusCode);
  }
}
export function getReasonForStatusCode(statusCode) {
  if (statusCode && statusCode >= 500) {
    return ReadyReason.SERVER_ERROR;
  }
  return ReadyReason.CLIENT_ERROR;
}