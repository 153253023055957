import { base64ToBase64Url, base64UrlToBase64 } from './util/base64url';
import { getEnv } from './env';
var productShorthands = {
  confluence: 'c',
  jira: 'j',
  stride: 's',
  bitbucket: 'b',
  trello: 't'
};
export function encode(originData) {
  var json = JSON.stringify(toJSONObject(originData));
  var base64 = getEnv().btoa(json);
  return base64ToBase64Url(base64);
}
export function decode(encodedOriginString) {
  var base64 = base64UrlToBase64(encodedOriginString);
  var json = getEnv().atob(base64);
  return fromJSONObject(JSON.parse(json));
}
export function toJSONObject(_ref) {
  var id = _ref.id,
    product = _ref.product;
  return {
    i: id,
    p: product && product in productShorthands ? productShorthands[product] : product
  };
}
export function fromJSONObject(originJSONObject) {
  var originData = {};
  if (originJSONObject.i) {
    originData.id = originJSONObject.i;
  }
  if (originJSONObject.p) {
    originData.product = expandProductShorthand(originJSONObject.p);
  }
  return originData;
}
function expandProductShorthand(maybeShorthand) {
  var products = Object.keys(productShorthands);
  for (var i = 0; i < products.length; i++) {
    var product = products[i];
    var shorthand = productShorthands[product];
    if (maybeShorthand === shorthand) {
      return product;
    }
  }
  return maybeShorthand;
}