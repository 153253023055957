import { css } from '@emotion/react';
import { gridSize } from '@atlaskit/theme';

const mobileMaxWidth = `${gridSize() * 100}px`;

const mediaQueryCss = (query: string) => (...args: any[]) => {
  // @ts-ignore
  const inner = css(...args);

  return css`
    @media ${query} {
      ${inner}
    }
  `;
};

const mediaQuery = {
  handheldQuery: `(max-width: ${mobileMaxWidth})`,
};

export const media = {
  handheld: mediaQueryCss(mediaQuery.handheldQuery),
  ...mediaQuery,
};

export type ScreenSize = 'mobile' | 'desktop' | 'unknown';
export const screenSizeMatcher = window.matchMedia && window.matchMedia(media.handheldQuery);
export const screenSize: () => ScreenSize = () =>
  screenSizeMatcher ? (screenSizeMatcher.matches ? 'mobile' : 'desktop') : 'unknown';
const maxWidth: (ScreenSize) => string = screenSize => {
  switch (screenSize) {
    case 'mobile':
      return mobileMaxWidth;
    default:
      return '100%';
  }
};

export type Viewport = {
  [key: string]: {
    name: string;
    styles: {
      width: string;
      height: string;
    };
  };
};

export const viewport: (ScreenSize, string?) => Viewport = (screenSize, name) => {
  const viewport = {};
  viewport[screenSize] = {
    name: name ? name : screenSize,
    styles: {
      width: maxWidth(screenSize),
      height: '100%',
    },
  };
  return viewport;
};
