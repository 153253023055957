import React from 'react';
import {
  AnalyticsClientImpl,
  AnalyticsClientContext,
} from '../utilities/analytics/analytics-web-client';
import { useIntl } from 'react-intl';
import { useAppSelector } from '../stores/redux.store';

type Props = {
  children?: React.ReactNode;
};

const AnalyticsClientProvider = ({ children }: Props) => {
  const { locale } = useIntl();

  const cobranding = useAppSelector(state => state.cobranding);
  const microbranding = useAppSelector(state => state.microbranding);
  const tenantCloudId = useAppSelector(state => state.context.tenantCloudId);
  const userId = useAppSelector(state => state.context.userId);

  return (
    <AnalyticsClientContext.Provider
      value={
        new AnalyticsClientImpl({
          cobranding,
          locale,
          microbranding,
          tenantCloudId,
          userId,
        })
      }>
      {children}
    </AnalyticsClientContext.Provider>
  );
};

export default AnalyticsClientProvider;
