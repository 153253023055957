import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
var _excluded = ["label", "svg", "size", "appearance", "iconGradientStart", "iconGradientStop", "iconColor", "textColor", "testId"];
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { sizes } from './constants';
var CSS_VAR_COLOR = '--logo-color';
var CSS_VAR_FILL = '--logo-fill';
var baseWrapperStyles = css({
  display: 'inline-block',
  position: 'relative',
  color: "var(".concat(CSS_VAR_COLOR, ")"),
  fill: "var(".concat(CSS_VAR_FILL, ")"),
  lineHeight: 1,
  userSelect: 'none',
  whiteSpace: 'normal',
  // eslint-disable-next-line @atlaskit/design-system/no-nested-styles
  '> svg': {
    height: '100%',
    fill: 'inherit'
  }
});
var stopColorStyles = css({
  // eslint-disable-next-line @atlaskit/design-system/no-nested-styles
  stop: {
    stopColor: 'currentColor'
  }
});
var sizeStyles = Object.entries(sizes).reduce(function (acc, _ref) {
  var _ref2 = _slicedToArray(_ref, 2),
    key = _ref2[0],
    val = _ref2[1];
  acc[key] = css({
    height: "".concat(val, "px")
  });
  return acc;
}, {});

/**
 * __Wrapper__
 *
 * An internal component used by `@atlaskit/logo` to render logo SVGs with correct styles.
 */
var Wrapper = function Wrapper(_ref3) {
  var _ref4;
  var label = _ref3.label,
    svg = _ref3.svg,
    size = _ref3.size,
    appearance = _ref3.appearance,
    iconGradientStart = _ref3.iconGradientStart,
    iconGradientStop = _ref3.iconGradientStop,
    iconColor = _ref3.iconColor,
    textColor = _ref3.textColor,
    userDefinedTestId = _ref3.testId,
    rest = _objectWithoutProperties(_ref3, _excluded);
  var shouldApplyStopColor = iconGradientStart === 'inherit' && iconGradientStop === 'inherit' && appearance === undefined;
  var testId = userDefinedTestId && "".concat(userDefinedTestId, "--wrapper");
  return jsx("span", _extends({
    css: [baseWrapperStyles, shouldApplyStopColor && stopColorStyles, size && sizeStyles[size]],
    "data-testid": testId,
    style: (_ref4 = {}, _defineProperty(_ref4, CSS_VAR_COLOR, iconColor), _defineProperty(_ref4, CSS_VAR_FILL, textColor), _ref4),
    "aria-label": label ? label : undefined,
    role: label ? 'img' : undefined,
    dangerouslySetInnerHTML: {
      __html: svg
    }
    // eslint-disable-next-line @repo/internal/react/no-unsafe-spread-props
  }, rest));
};
export default Wrapper;