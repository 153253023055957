import { captureErrorMessage } from './analytics/error-reporting';
import { ServerError } from '../types';

export const fetchApi = async (url: string, headers?: HeadersInit) => {
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  });

  if (!response.ok) {
    const data = await response.json();
    const message = `Failed to fetch ${url} [${response.status} status]`;
    const errorType = (data?.error as ServerError) || { error: 'internal_server_error' };
    void captureErrorMessage(message, data);
    throw new ServerErrorException(message, response.status, errorType);
  }

  return response;
};

export class ServerErrorException extends Error {
  constructor(public message: string, public status: number, public errorType: ServerError) {
    super(message);
    this.name = 'ServerErrorException';
  }
}
