import _defineProperty from "@babel/runtime/helpers/defineProperty";
/* eslint-disable @atlaskit/design-system/no-nested-styles */
/**
 * @jsxRuntime classic
 */
/** @jsx jsx */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import Button from '@atlaskit/button/custom-theme-button';
import Inline from '@atlaskit/primitives/inline';
import { DEFAULT_APPEARANCE, VAR_COLOR, VAR_BG_COLOR, VAR_BG_COLOR_HOVER, VAR_BG_COLOR_ACTIVE } from './constants';
import { actionBackgroundColor, actionTextColor } from './theme';
var buttonStyles = css({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
  '&&, a&&': {
    // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage, @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
    padding: "0 ".concat("var(--ds-space-100, 8px)", " !important"),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    background: "var(".concat(VAR_BG_COLOR, ")"),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    color: "var(".concat(VAR_COLOR, ") !important"),
    fontWeight: "var(--ds-font-weight-medium, 500)"
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
  '&&:hover, &&:active, a&&:hover, a&&:active': {
    textDecoration: 'underline'
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
  '&&:hover': {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    backgroundColor: "var(".concat(VAR_BG_COLOR_HOVER, ")")
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
  '&&:active': {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    backgroundColor: "var(".concat(VAR_BG_COLOR_ACTIVE, ")")
  }
});
var appearanceNormalButtonStyles = css({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
  '&&, a&&': {
    // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage, @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
    padding: '0 !important'
  }
});
var appearanceNormalActionsContainerStyles = css({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
  '&&, a&&': {
    transform: 'translateX(-2px)'
  }
});
var FlagActions = function FlagActions(props) {
  var _props$appearance = props.appearance,
    appearance = _props$appearance === void 0 ? DEFAULT_APPEARANCE : _props$appearance,
    _props$actions = props.actions,
    actions = _props$actions === void 0 ? [] : _props$actions,
    linkComponent = props.linkComponent,
    testId = props.testId;
  if (!actions.length) {
    return null;
  }
  var isBold = appearance !== DEFAULT_APPEARANCE;
  return jsx("span", {
    css: !isBold && appearanceNormalActionsContainerStyles
  }, jsx(Inline, {
    space: "space.100",
    shouldWrap: true,
    alignBlock: "center",
    separator: isBold ? undefined : '·',
    testId: testId && "".concat(testId, "-actions")
  }, actions.map(function (action, index) {
    var _ref;
    return jsx(Button, {
      onClick: action.onClick,
      href: action.href,
      target: action.target,
      appearance: isBold ? 'default' : 'link',
      component: linkComponent,
      spacing: "compact",
      testId: action.testId,
      key: index
      // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
      ,
      style: (_ref = {}, _defineProperty(_ref, VAR_COLOR, actionTextColor[appearance]), _defineProperty(_ref, VAR_BG_COLOR, actionBackgroundColor[appearance].default), _defineProperty(_ref, VAR_BG_COLOR_HOVER, actionBackgroundColor[appearance].pressed), _defineProperty(_ref, VAR_BG_COLOR_ACTIVE, actionBackgroundColor[appearance].active), _ref)
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
      ,
      css: [buttonStyles, appearance === DEFAULT_APPEARANCE && appearanceNormalButtonStyles]
    }, action.content);
  })));
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default FlagActions;