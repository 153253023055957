export interface State {
  deleteOn: string;
}

export const defaultState: State = {
  deleteOn: '',
};

const cancelDeletion = (state: State = defaultState): State => {
  return state;
};

export default cancelDeletion;
