import { JiraLogo } from '@atlaskit/logo';
import { JiraBackground } from '@atlassian/product-wireframes';
import { CobrandingComponents } from '../shared-cobranding';

const JiraCobranding: CobrandingComponents = {
  background: JiraBackground,
  logo: { component: JiraLogo },
  footerProducts: 'Jira, Confluence, Trello',
};

export default JiraCobranding;
