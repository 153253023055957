import React, { useMemo } from 'react';
import Helmet from 'react-helmet';
import { useSearchParams } from 'react-router-dom';

type MobileApp = 'jira' | 'confluence';

const mobileAppToUrlPatterns: Record<MobileApp, string[]> = {
  jira: ['/secure', '/issues', '/browse', '#issue', '#myjirahome', '#browse'],
  confluence: ['/wiki'],
};

const iosAppId: Record<MobileApp, string> = {
  jira: '1006972087',
  confluence: '1006971684',
};

const slackAppId: Record<MobileApp, string> = {
  jira: 'A2RPP3NFR',
  confluence: 'AC23SDS77',
};

/**
 * Checks if the continueUrl contains the specified pattern.
 * Additionally searches for the url encoded form of the pattern,
 * which can be encoded up to 3 times e.g. openId continue urls.
 */
const checkForPattern = (continueUrl: string, pattern: string): boolean => {
  if (continueUrl.includes(pattern)) {
    return true;
  }

  const encode1 = encodeURIComponent(pattern);
  const encode2 = encodeURIComponent(encode1);
  const encode3 = encodeURIComponent(encode2);

  return (
    continueUrl.includes(encode1) || continueUrl.includes(encode2) || continueUrl.includes(encode3)
  );
};

/**
 * Adds mobile app meta tags to the document head if the continueUrl matches a pattern configured for mobile apps.
 */
const MobileAppMetaTags = () => {
  const [queryParams] = useSearchParams();
  const continueUrl = queryParams.get('continue');

  const maybeMobileApp: MobileApp | null = useMemo<MobileApp | null>(() => {
    if (!continueUrl) return null;
    for (const [mobileApp, urlPatterns] of Object.entries(mobileAppToUrlPatterns)) {
      if (urlPatterns.find(urlPattern => checkForPattern(continueUrl, urlPattern))) {
        return mobileApp as MobileApp;
      }
    }
    return null;
  }, [continueUrl]);

  return (
    maybeMobileApp && (
      <Helmet>
        <meta name="apple-itunes-app" content={`app-id=${iosAppId[maybeMobileApp]}`} />
        <meta name="slack-app-id" content={slackAppId[maybeMobileApp]} />
      </Helmet>
    )
  );
};

export default MobileAppMetaTags;
