import { OidcDisplay, OidcContext } from '../../types';

export function isOidcDisplayMode(context?: OidcContext, ...display: Array<OidcDisplay>) {
  if (!context || !context.display) {
    return false;
  }

  return display.includes(context.display);
}

export function isMobileOidc(context?: OidcContext) {
  // currently we assume that any oidc with proper display param is a mobile oidc
  return !!context && !!context.display;
}
