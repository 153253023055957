import { defineMessages } from 'react-intl';

export default defineMessages({
  loginHeadTitle: {
    id: 'login.head.title',
    defaultMessage: 'Log in to continue',
    description: '',
  },

  // Flags
  mfRecoveryEmailResentSuccessTitle: {
    id: 'mf.recoveryemailresentsuccess.title',
    defaultMessage: 'Recovery email resent!',
    description: '',
  },
  mfRecoveryEmailResentSuccessDesc: {
    id: 'mf.recoveryemailresentsuccess',
    defaultMessage: 'A recovery email has been resent to you!',
    description: '',
  },
  mfRecoveryEmailResentErrorTitle: {
    id: 'mf.recoveryemailresenterror.title',
    defaultMessage: 'Something went wrong.',
    description: '',
  },
  mfRecoveryEmailResentErrorDesc: {
    id: 'mf.recoveryemailresenterror',
    defaultMessage: 'We couldn’t send the recovery email. Please try again later.',
    description: '',
  },
  mfRecoverySMSResentSuccessTitle: {
    id: 'mf.recoverysmsresentsuccess.title',
    defaultMessage: 'SMS resent!',
    description: '',
  },
  mfRecoverySMSResentSuccessDesc: {
    id: 'mf.recoverysmsresentsuccess',
    defaultMessage: 'A SMS has been resent to you!',
    description: '',
  },
  mfEmailResentSuccessTitle: {
    id: 'mf.emailresentsuccess.title',
    defaultMessage: 'Email resent!',
    description: '',
  },
  mfEmailResentSuccessDesc: {
    id: 'mf.emailresentsuccess',
    defaultMessage: 'An Email has been resent to you!',
    description: '',
  },
  mfVerifyErrorTitle: {
    id: 'guardian.error.default.flag.title',
    defaultMessage: "Oops, that didn't work!",
  },
  mfVerifyErrorDesc: {
    id: 'guardian.error.default.flag.description',
    defaultMessage: 'Try that again in a moment.',
  },
});
